import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'


const LinkImg = styled.img`
  margin-bottom: 0;
`

const LinkIcon = ({ data }) => {
  const src =
    data.allWordpressPage.edges[0].node.acf.icons.link_icon.url.source_url
  return <LinkImg src={src} alt="" />
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                icons {
                  link_icon {
                    url {
                      source_url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <LinkIcon data={data} />}
  />
)
