import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Hero from './hero'
import Navigation from '../elements/navigation'
import Layout from '../../components/layout'
import Footer from '../../components/footer'
import FooterBlocks from '../elements/footer-blocks'
import PostContent from '../post-page/post-content'
import Text from '../elements/text'
import postPath from '../../utils/post-path'

const PER_PAGE = 3

const PagerContainer = styled.div`
  list-style: none;
  margin: 0;
  display: flex;
`

const PagerItem = styled.li`
  margin: 0 10px;
`

const buildPager = (pages, onClick, currentPage) => {
  return (
    <PagerContainer>
      {Array.from({ length: pages }, (_, i) => {
        return (
          <PagerItem
            key={i}
            onClick={() => onClick(i + 1)}
            className={currentPage === i + 1 ? 'current' : ''}
          >
            <Text small dark bold={currentPage === i + 1}>
              {i + 1}
            </Text>
          </PagerItem>
        )
      })}
    </PagerContainer>
  )
}

const Pager = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
`

const PagerIcon = styled.img`
  margin: 0;
  height: 38px;
  transform: ${({ back }) => (back ? 'rotate(180deg)' : '0')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`

const handlePage = (setPage, allPages, nextPage) => {
  if (nextPage <= 0) return
  if (nextPage > allPages) return

  window.scrollTo({ top: 0 });
  setPage(nextPage)
}

const PostIndex = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const posts = data.blogPosts.edges
  const iconUrl = data.blogPage.edges[0].node.acf.pager_icon.url.source_url
  const pages = Math.ceil(posts.length / PER_PAGE)
  const offset = (currentPage - 1) * PER_PAGE
  return (
    <Layout>
      <div>
        <Navigation />
        <Hero />
        {posts.slice(offset, offset + PER_PAGE).map(({ node }) => {
          node.path = postPath('/blog/', node)
          return <PostContent node={node} redacted />
        })}
        <Pager>
          <PagerIcon
            src={iconUrl}
            back
            disabled={currentPage === 1}
            onClick={() => handlePage(setCurrentPage, pages, currentPage - 1)}
          />
          {buildPager(pages, setCurrentPage, currentPage, iconUrl)}
          <PagerIcon
            src={iconUrl}
            disabled={currentPage === pages}
            onClick={() => handlePage(setCurrentPage, pages, currentPage + 1)}
          />
        </Pager>
        <FooterBlocks />
        <Footer style={{ marginTop: 0 }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PostIndexQuery {
    blogPage: allWordpressPage(filter: { slug: { eq: "blog" } }) {
      edges {
        node {
          id
          acf {
            pager_icon {
              url {
                source_url
              }
            }
          }
        }
      }
    }
    blogPosts: allWordpressPost {
      edges {
        node {
          id
          title
          content
          acf {
            snippet
            hero {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxHeight: 1500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PostIndex
