import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import styled from 'styled-components'
import Navigation from '../elements/navigation'
import Footer from '../../components/footer'
import FooterBlocks from '../elements/footer-blocks'
import PostNavigation from './post-navigation'
import PostContent from './post-content'
import { SeoContext } from '../../seo-context'

const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
`

const PostPage = ({ data, pageContext, location: { href } }) => {
  const node = data.blogPost.edges[0].node
  const { updateFields } = useContext(SeoContext);
  updateFields({...node.acf.seo_fields, url: href});
  return (
    <Layout>
      <Navigation style={{ background: '#887001' }} />
      <PostContent node={node} />
      <NavigationContainer>
        <PostNavigation previous {...pageContext.previousPost} />
        <PostNavigation {...pageContext.nextPost} />
      </NavigationContainer>
      <FooterBlocks />
      <Footer style={{ marginTop: 0 }} />
    </Layout>
  )
}

export const query = graphql`
  query PostPageQuery($id: String) {
    blogPost: allWordpressPost(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          content
          acf {
            seo_fields {
              title
              twitter_image_alt_text
              description
              image {
                source_url
              }
            }
            hero {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxHeight: 1500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PostPage
