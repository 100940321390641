import React, { useContext } from 'react'
import styled from 'styled-components'
import Column from '../column'
import {SeoContext} from '../../../seo-context'

const RowContainer = styled.div`
  display: flex;
  margin: 26px 0;
  justify-content: space-between;
  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    margin: 0 0;
  }
`

const groupedColumns = columns => {
  return columns.reduce((acc, column) => {
    if (column.colspan === 'double') {
      acc.push([column])
    } else if (acc.length > 0 && acc[acc.length - 1][0].colspan !== 'double') {
      acc[acc.length - 1].push(column)
    } else {
      acc.push([column])
    }

    return acc
  }, [])
}

const ColSpanContainer = styled.div``

const Row = ({ row, seoFields }) => {
  const { updateFields } = useContext(SeoContext);
  if (seoFields) {
    updateFields(seoFields)
  }
  if (row.column.filter(({ colspan }) => colspan === 'double').length) {
    const grouped = groupedColumns(row.column)

    return (
      <RowContainer {...row}>
        {grouped.map((group, idx) => {
          return (
            <ColSpanContainer>
              {group.map((column, idx) => {
                return (
                  <Column
                    column={column}
                    rowColumns={grouped.length}
                    key={idx}
                  />
                )
              })}
            </ColSpanContainer>
          )
        })}
      </RowContainer>
    )
  }

  return (
    <RowContainer {...row}>
      {row.column.map((column, idx) => {
        return <Column column={column} rowColumns={row.column.length} key={idx} />
      })}
    </RowContainer>
  )
}

export default Row
