import React from 'react'

const VimeoVideo = ({ column: { video_id } }) => {
  return (
    <iframe
      src={`https://player.vimeo.com/video/${video_id}`}
      style={{
        width: '100%',
        height: '100%',
      }}
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
  )
}

export default VimeoVideo
