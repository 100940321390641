import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Text from '../../elements/text'
import Img from 'gatsby-image'
import Carousel from '../../elements/carousel'

const Container = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 0 15px;
`

const ContainerBox = styled.div`
  flex: 1;
  max-width: 945px;
  border-radius: 24px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.lightGrayColor};
`

const QuoteWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
`

const QuoteContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 25px 25px 25px;
`

const Quote = styled(Text)`
  text-align: center;
`

const Name = styled(Text)`
  margin-top: 17px;
  margin-bottom: 12px;
`

const PullQuote = ({ data }) => {
  const settings = {
    infinite: true,
    variableWidth: false,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 2000,
  }
  const fields = data.allWordpressPage.edges[0].node.acf.pull_quote_carousel
  return (
    <Container>
      <ContainerBox>
        <Carousel settings={settings}>
          {fields.map((quoteObj, idx) => {
            return (
              <div key={idx} style={{ width: '945px' }}>
                <QuoteWrapper>
                  <QuoteContainer>
                    <Quote bold dark medium>
                      {quoteObj.quote}
                    </Quote>
                    <Name light grey bold>
                      {quoteObj.name}
                    </Name>
                    <Img
                      fluid={quoteObj.logo.localFile.childImageSharp.fluid}
                      style={{ height: 'auto', width: '120px' }}
                    />
                  </QuoteContainer>
                </QuoteWrapper>
              </div>
            )
          })}
        </Carousel>
      </ContainerBox>
    </Container>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                pull_quote_carousel {
                  name
                  quote
                  logo {
                    localFile {
                      childImageSharp {
                        fluid(quality: 100, maxWidth: 250) {
                          ...GatsbyImageSharpFluid
                          presentationWidth
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <PullQuote data={data} />}
  />
)
