import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layout'
import styled from 'styled-components'
import Navigation from '../elements/navigation'
import Footer from '../footer'
import FooterBlocks from '../elements/footer-blocks'
import Text from '../elements/text'
import Img from 'gatsby-image/withIEPolyfill'
import postPath from '../../utils/post-path'

const Container = styled.div`
  max-width: 914px;
  margin: 0 auto;
  padding: 81px 20px;

  @media (min-width: 1150px) {
    max-width: 1100px;
  }

  @media (min-width: 1550px) {
    max-width: 1200px;
  }
`

const CaseStudyPreviewContainer = styled.a`
  display: block;
  height: 433px;
  width: 100%;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 20px;

  @media (min-width: 1150px) {
    height: 500px;
  }

  .preview-project-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #715e05;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    z-index: 5;
    mix-blend-mode: soft-light;
  }

  &:hover {
    .preview-project-overlay {
      opacity: 0.8;
    }
  }
`

const CaseStudyCopyContainer = styled.div`
  position: absolute;
  left: 50px;
  bottom: 100px;
`

const VieCaseStudyContainer = styled.a`
  z-index: 10;
  position: absolute;
  bottom: 20px;
  right: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
`

const ProjectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 866px) {
    justify-content: center;
  }
`

const ProjectPreviewContainer = styled.a`
  height: 206px;
  width: 413px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: stretch;
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;

  @media (min-width: 1150px) {
    height: 300px;
    width: 508px;
  }

  @media (min-width: 1550px) {
    height: 325px;
    width: 555px;
  }

  .project-details {
    opacity: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: opacity 0.35s ease;
  }

  .preview-project-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: white;
    opacity: 0;
    z-index: 5;
    border: 5px solid #715e05;
    transition: opacity 0.35s ease;
  }

  &:hover {
    .preview-project-overlay,
    .project-details {
      opacity: 1;
    }
  }
`

const ProjectDivider = styled.div`
  height: 4px;
  width: 30px;
  background-color: #715e05;
  z-index: 10;
  margin: 20px 0;
`

const ProjectText = styled(Text)`
  z-index: 10;
  @media (max-width: 866px) {
    line-height: 1.26;
    margin-top: 10px;
  }
`

const ViewProjectContainer = styled.div`
  z-index: 10;
  margin: 10px 0;
  display: flex;
  align-items: center;
`

const LinkIcon = styled.img`
  height: 25px;
  width: auto;
  margin: 0 10px;
`

const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
`

const Show = ({ data, pageContext }) => {
  const caseStudies = pageContext.case_study_ids.map(id => {
    return data.case_studies.edges.filter(e => e.node.wordpress_id === id)[0]
  })

  const projects = pageContext.project_ids.map(id => {
    return data.projects.edges.filter(e => e.node.wordpress_id === id)[0]
  })
  const projectLinkIcon =
    data.pageDefaults.edges[0].node.acf.project_link_icon.url.source_url
  const caseStudyLinkIcon =
    data.pageDefaults.edges[0].node.acf.case_study_link_icon.url.source_url

  const elements = []
  let loop = 1
  while (caseStudies.length > 0 || projects.length > 0) {
    const caseStudy = caseStudies.shift()
    if (caseStudy) {
      const preview = caseStudy.node.acf.case_study_preview
      elements.push(
        <CaseStudyPreviewContainer key={caseStudy.node.id} href={postPath('/case-studies/', caseStudy.node)}>
          <div className="preview-project-overlay" />
          {/* <StyledImg src={preview.image.localFile.childImageSharp.fixed} /> */}
          <StyledImg src={preview.image.source_url} />
          <CaseStudyCopyContainer>
            <Text white medium bold>
              {preview.copy_line_1}
            </Text>
            <Text white medium bold>
              {preview.copy_line_2}
            </Text>
            <Text white medium bold>
              {preview.copy_line_3}
            </Text>
          </CaseStudyCopyContainer>
          <VieCaseStudyContainer>
            <Text white small bold>
              CASE STUDY
            </Text>
            <LinkIcon src={caseStudyLinkIcon} />
          </VieCaseStudyContainer>
        </CaseStudyPreviewContainer>
      )
    }

    let nextProjects = projects.splice(0, 4)
    if (nextProjects.length > 0) {
      elements.push(
        <ProjectContainer key={loop}>
          {nextProjects.map((p, idx) => {
            const preview = p.node.acf.project_preview
            return (
              <ProjectPreviewContainer key={idx} href={postPath('/projects/', p.node)}>
                <div className="preview-project-overlay" />
                {/* <StyledImg
                  src={preview.image.localFile.childImageSharp.fixed}
                /> */}
                <StyledImg
                  src={preview.image.source_url}
                />
                {/* <ProjectImage src={preview.image.source_url} /> */}
                <div className="project-details">
                  <ProjectText gold small bold>
                    {preview.client_name}
                  </ProjectText>
                  <ProjectText gold medium bold>
                    {preview.project_name}
                  </ProjectText>
                  <ProjectDivider />
                  <ViewProjectContainer>
                    <Text black small>
                      VIEW PROJECT
                    </Text>{' '}
                    <LinkIcon src={projectLinkIcon} />
                  </ViewProjectContainer>
                </div>
              </ProjectPreviewContainer>
            )
          })}
        </ProjectContainer>
      )
      loop += 1
    }
  }

  return (
    <Layout>
      <Navigation light />
      <Container>{elements}</Container>
      <FooterBlocks />
      <Footer style={{ marginTop: 0 }} />
    </Layout>
  )
}

export const query = graphql`
  query WorksPageQuery($project_ids: [Int], $case_study_ids: [Int]) {
    pageDefaults: allWordpressPage(filter: { slug: { eq: "works-index" } }) {
      edges {
        node {
          acf {
            project_link_icon {
              url {
                source_url
              }
            }
            case_study_link_icon {
              url {
                source_url
              }
            }
          }
        }
      }
    }
    projects: allWordpressWpProject(
      filter: { wordpress_id: { in: $project_ids } }
    ) {
      edges {
        node {
          id
          title
          wordpress_id
          acf {
            project_preview {
              image {
                source_url
                # localFile {
                  # childImageSharp {
                  #   fixed(quality: 100, width: 425, height: 215) {
                  #     ...GatsbyImageSharpFixed
                  #   }
                  # }
                # }
              }
              client_name
              project_name
            }
            page_content_project {
              id
            }
          }
        }
      }
    }
    case_studies: allWordpressWpCaseStudy(
      filter: { wordpress_id: { in: $case_study_ids } }
    ) {
      edges {
        node {
          id
          title
          wordpress_id
          acf {
            case_study_preview {
              image {
                source_url
                # localFile {
                #   childImageSharp {
                #     fixed(quality: 100, height: 413) {
                #       ...GatsbyImageSharpFixed
                #     }
                #   }
                # }
              }
              copy_line_1
              copy_line_2
              copy_line_3
            }
            page_content_case_study {
              id
            }
          }
        }
      }
    }
  }
`

export default Show
