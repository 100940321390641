import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import ShowContent from '../show/index'

const Show = ({ data, pageContext: { caseStudies }, location: { href } }) => {
  const node = data.caseStudy.edges[0].node

  return (
    <>
      <ShowContent
        node={node}
        seoFields={{...node.acf.seo_fields, url: href}}
        resources={node.acf.page_content_case_study}
        siblingResources={caseStudies}
        resourcePath="/case-studies/"
      />
    </>
  )
}

export const query = graphql`
  query CaseStudyShowPageQuery($id: String) {
    caseStudy: allWordpressWpCaseStudy(filter: { id: { eq: $id } }) {
      edges {
        node {
          wordpress_id
          id
          title
          content
          acf {
            seo_fields {
              title
              twitter_image_alt_text
              description
              # image {
              #   source_url
              # }
            }
            page_content_case_study {
              column {
                acf_fc_layout
                text_group {
                  body
                  headline
                }
                image {
                  localFile {
                    childImageSharp {
                      fixed(quality: 100, height: 395, width: 395) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                  source_url
                }
                stat_group {
                  headline
                  sub_stats {
                    text
                  }
                }
                section_headline
                sub_section_headline
                quote
                attributed_to
                align
                colspan
                video_id
                image_text_line_1
                image_text_line_2
                image_text_line_3
                narrow
                forced_width
              }
            }
          }
        }
      }
    }
  }
`

export default Show
