// postPath
const he = require('he')

module.exports = (base, node) => {
  if (node === null || !node.title) return

  const title = he
    .decode(node.title)
    .replace(/[^A-Z0-9\s]/gi, '')
    .replace(/\s+/gi, '-')
    .toLowerCase()

  return `${base}${title}`
}
