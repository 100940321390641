import React from 'react'
import styled from 'styled-components'
import Text from '../../elements/text'

const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  margin: 0;
  height: 100%;
  width: 100%;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const TextContainer = styled.div`
  position: absolute;
  left: 35px;
  bottom: 35px;
`

const ImageBlock = ({ image, image_text_line_1, image_text_line_2, image_text_line_3 }) => {
  return (
    <Container>
      <Image src={image && image.source_url}></Image>
      <TextContainer>
        <Text medium white bold>{image_text_line_1}</Text>
        <Text medium white bold>{image_text_line_2}</Text>
        <Text medium white bold>{image_text_line_3}</Text>
      </TextContainer>
    </Container>
  )
}

export default ImageBlock
