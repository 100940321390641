import styled from 'styled-components'

const weightReducer = ({ theme, ...rest }) => {
  if (rest.bold) {
    return theme.fontWeightBold
  } else {
    return theme.fontWeightNormal
  }
}

const colorReducer = ({ theme, ...rest }) => {
  if (rest.dark) {
    return theme.fontColorDark
  } else if (rest.grey) {
    return theme.fontColorGrey
  } else if (rest.black) {
    return theme.fontColorBlack
  } else if (rest.gold) {
    return theme.fontColorGold
  } else {
    return theme.fontColorNormal
  }
}

const sizeReducer = ({ theme, ...rest }) => {
  if (rest.xLarge) {
    return theme.fontSizeExtraLarge
  } else if (rest.large) {
    return theme.fontSizeLarge
  } else if (rest.medium) {
    return theme.fontSizeMedium
  } else if (rest.small) {
    return theme.fontSizeSmall
  } else {
    return theme.fontSizeNormal
  }
}

const Text = styled.p`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${weightReducer};
  color: ${colorReducer};
  font-size: ${sizeReducer};
  line-height: 1.6;
  margin: 0;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export default Text
