import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '../../elements/text'
import media from '../../../utils/media'

const SubHeadlineContainer = styled.div`
  background-color: ${props => props.theme.bgColor};
  padding: 100px 0 100px 300px;
  position: relative;

  ${media.tablet`
    padding-left: 100px;
    padding-right: 30px;
  `}
  ${media.phone`
    padding-left: 30px;
    padding-right: 30px;
  `}
`
const SubHeadlineTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  width: 100%;
  max-width: 600px;
  position: relative;
  z-index: 10;
`

const SubHeadlineText = styled(Text)`
  ${media.phone`
    font-size: 2.2em;
  `}
`

class SubHeadline extends React.Component {
  containerRef = React.createRef()
  render() {
    return (
      <SubHeadlineContainer ref={this.containerRef}>
        <SubHeadlineTextContainer>
          <SubHeadlineText medium bold>
            {this.props.data.allWordpressPage.edges[0].node.acf.sub_headline}
          </SubHeadlineText>
        </SubHeadlineTextContainer>
      </SubHeadlineContainer>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                sub_headline
              }
            }
          }
        }
      }
    `}
    render={data => <SubHeadline data={data} {...props} />}
  />
)

SubHeadline.propTypes = {
  data: PropTypes.shape({
    allWordpressPage: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            acf: PropTypes.shape({
              sub_headline: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}
