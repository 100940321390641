import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Text from './elements/text'
import Img from 'gatsby-image'
import media from '../utils/media'

const Container = styled.div`
  margin-top: 100px;
  min-height: 125px;
  width: 100%;
  padding: 10px 60px;
  padding-bottom: 10px;
  display: flex;
  background-color: ${({ theme }) => theme.bgColor};
  flex-direction: column;
  align-items: center;
  ${media.phone`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `};
`

const Row = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1025px;
  ${media.phone`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `};
`

const TopRow = styled(Row)`
  justify-content: flex-start;
  align-items: center;
`

const BottomRow = styled(Row)`
  align-items: flex-end;
`

const SocialLinks = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  ${media.phone`
    margin: 10px 0;
  `};
`

const SocialLink = styled.a`
  height: 26px;
  width: 26px;
  background-color: #715e05;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 5px;
`

const SocialIcon = styled.img`
  height: 12px;
  width: auto;
  margin: 0;
`

const NavigationLink = styled.a`
  margin: 0 20px;
  text-decoration: none;
  ${media.phone`
    margin: 10px 0px;
  `};
`

const Footer = ({ data, style }) => {
  const homePageFields = data.homePage.edges[0].node.acf.footer
  const navigationItems =
    data.navigationPage.edges[0].node.acf.menu_items.menu_item
  const copyWriteText = `© ${new Date().getFullYear()} Hunt, Gather LLC`
  return (
    <Container style={{ ...style }}>
      <TopRow>
        <Img
          fixed={homePageFields.logo.localFile.childImageSharp.fixed}
        />
        {navigationItems.map((item, idx) => (
          <NavigationLink href={item.link_location} key={idx}>
            <Text small bold>
              {item.display_text}
            </Text>
          </NavigationLink>
        ))}
        <SocialLinks>
          {homePageFields.social_links.map((sl, i) => {
            return (
              <SocialLink href={sl.link} key={i}>
                <SocialIcon src={sl.social_logo.url.source_url} />
              </SocialLink>
            )
          })}
        </SocialLinks>
      </TopRow>
      <BottomRow>
        <Text small grey>
          {copyWriteText}
        </Text>
      </BottomRow>
    </Container>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        homePage: allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                footer {
                  logo {
                    localFile {
                      childImageSharp {
                        fixed(quality: 100, width: 178) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  social_links {
                    link
                    social_logo {
                      url {
                        source_url
                      }
                    }
                  }
                }
              }
            }
          }
        }
        navigationPage: allWordpressPage(
          filter: { slug: { eq: "navigation-bar" } }
        ) {
          edges {
            node {
              acf {
                menu_items {
                  menu_item {
                    display_text
                    link_location
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
