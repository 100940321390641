import React from 'react'
import Hero from '../components/home-page/hero'
import SubHeadline from '../components/home-page/sub-headline'
import Parallax from '../components/home-page/parallax'
import Services from '../components/home-page/services'
import AboutUs from '../components/home-page/about-us'
import LogoSoup from '../components/home-page/logo-soup'
import PullQuote from '../components/home-page/pull-quote'
import ContactUs from '../components/home-page/contact-us'
import Footer from '../components/footer'
import Layout from '../components/layout'
import Slider from '../components/elements/slider'
import styled from 'styled-components'
import Navigation from '../components/elements/navigation'

const TopContainer = styled.div`
  position: relative;
`

export default class HomePage extends React.Component {
  containerRef = React.createRef()
  render() {
    return (
      <Layout>
        <TopContainer ref={this.containerRef}>
          <Navigation />
          <Slider containerRef={this.containerRef} offset={80} />
          <Hero />
          <SubHeadline />
        </TopContainer>
        <Parallax />
        <Services />
        <AboutUs />
        <LogoSoup />
        <PullQuote />
        <ContactUs />
        <Footer />
      </Layout>
    )
  }
}
