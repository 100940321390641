import styled, { css } from 'styled-components'

const calcFunction = ({
  baseValue,
  col = null,
  nar = null,
  mobileScale = false,
}) => {
  return ({ height, narrow, colspan }) => {
    if (!height) return ''
    if (height && colspan) return `height: ${col || baseValue * 2}px;`
    if (height && narrow) return `height: ${nar || baseValue / 2}px`
    return `height: ${baseValue / (mobileScale ? 2 : 1)}px;`
  }
}

const ColumnContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'flex-start'};

  ${({ columns }) => {
    switch (columns) {
      case 1:
        return css`
          width: 100%;
          ${calcFunction({ baseValue: 460 })}
        `
      case 2:
        return css`
          width: 460px;
          ${calcFunction({ baseValue: 460 })}
        `
      case 3:
        return css`
          width: 275px;
          ${calcFunction({ baseValue: 275, col: 790 })}
        `
      case 4:
        return css`
          width: 230px;
          ${calcFunction({ baseValue: 230, col: 790 })}
        `
      default:
        return ''
    }
  }}

  @media (min-width: 840px) {
    ${({ columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 100%;
            ${calcFunction({ baseValue: 420 })}
          `
        case 2:
          return css`
            width: 420px;
            ${calcFunction({ baseValue: 420 })}
          `
        case 3:
          return css`
            width: 450px;
            ${calcFunction({ baseValue: 450 })}
          `
        case 4:
          return css`
            width: 210px;
            ${calcFunction({ baseValue: 210 })}
          `
        default:
          return ''
      }
    }}
  }

  @media (min-width: 1100px) {
    ${({ columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 100%;
            ${calcFunction({ baseValue: 465 })}
          `
        case 2:
          return css`
            width: 465px;
            ${calcFunction({ baseValue: 465 })}
          `
        case 3:
          return css`
            width: 450px;
            ${calcFunction({ baseValue: 450 })}
          `
        case 4:
          return css`
            width: 232.5px;
            ${calcFunction({ baseValue: 232.5, col: 420 })}
          `
        default:
          return ''
      }
    }}
  }

  @media (min-width: 1500px) {
    ${({ columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 100%;
            ${calcFunction({ baseValue: 560 })}
          `
        case 2:
          return css`
            width: 560px;
            ${calcFunction({ baseValue: 560 })}
          `
        case 3:
          return css`
            width: 450px;
            ${calcFunction({ baseValue: 450 })}
          `
        case 4:
          return css`
            width: 280px;
            ${calcFunction({ baseValue: 280, col: 540 })}
          `
        default:
          return ''
      }
    }}
  }

  @media (max-width: 940px) {
    ${({ columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 100%;
            ${calcFunction({ baseValue: 400 })}
          `
        case 2:
          return css`
            width: 400px;
            ${calcFunction({ baseValue: 400 })}
          `
        case 3:
          return css`
            width: 225px;
            ${calcFunction({ baseValue: 225, col: 700 })}
          `
        case 4:
          return css`
            width: 200px;
            ${calcFunction({ baseValue: 200 })}
          `
        default:
          return ''
      }
    }}
  }

  @media (max-width: 850px) {
    ${({ columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 100%;
            ${calcFunction({ baseValue: 375 })}
          `
        case 2:
          return css`
            width: 375px;
            ${calcFunction({ baseValue: 375 })}
          `
        case 3:
          return css`
            width: 225px;
            ${calcFunction({ baseValue: 225, col: 700 })}
          `
        case 4:
          return css`
            width: 187.5px;
            ${calcFunction({ baseValue: 187.5, col: 370 })}
          `
        default:
          return ''
      }
    }}
  }

  @media (max-width: 800px) {
    ${({ columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 100%;
            ${calcFunction({ baseValue: 350 })}
          `
        case 2:
          return css`
            width: 350px;
            ${calcFunction({ baseValue: 350 })}
          `
        case 3:
          return css`
            width: 225px;
            ${calcFunction({ baseValue: 225, col: 700 })}
          `
        case 4:
          return css`
            width: 175px;
            ${calcFunction({ baseValue: 175, col: 370 })}
          `
        default:
          return ''
      }
    }}
  }

  @media (max-width: 750px) {
    ${({ columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 100%;
            ${calcFunction({ baseValue: 325 })}
          `
        case 2:
          return css`
            width: 325px;
            ${calcFunction({ baseValue: 325 })}
          `
        case 3:
          return css`
            width: 175px;
            ${calcFunction({ baseValue: 175, col: 600 })}
          `
        case 4:
          return css`
            width: 162.5px;
            ${calcFunction({ baseValue: 162.5, col: 600 })}
          `
        default:
          return ''
      }
    }}
  }

  @media (max-width: 700px) {
    ${({ columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 100%;
            ${calcFunction({ baseValue: 300 })}
          `
        case 2:
          return css`
            width: 300px;
            ${calcFunction({ baseValue: 300, col: 650 })}
          `
        case 3:
          return css`
            width: 175px;
            ${calcFunction({ baseValue: 175, col: 600 })}
          `
        case 4:
          return css`
            width: 150px;
            ${calcFunction({ baseValue: 150, col: 600 })}
          `
        default:
          return ''
      }
    }}
  }

  @media (max-width: 640px) {
    ${({ height, colspan, columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 100%;
            ${calcFunction({ baseValue: 600, mobileScale: true })}
          `
        case 2:
          return css`
            width: 600px;
            ${calcFunction({ baseValue: 600 })}
          `
        case 3:
          return css`
            width: 175px;
            ${calcFunction({ baseValue: 175, col: 600 })}
          `
        case 4:
          return css`
            width: 600px;
            ${({ height, narrow, colspan }) => {
              if (!height) return ''
              if (height && colspan) return 'height: 100%;'
              if (height && narrow) return 'height: calc(600px / 2);'
              return 'height: 600px;'
            }}
          `
        default:
          if (height && colspan) {
            return css`
              width: 100%;
              height: 500px;
            `
          }
          return css`
            width: 100%;
          `
      }
    }}
  }

  @media (max-width: 600px) {
    ${({ height, colspan, columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 100%;
            ${calcFunction({ baseValue: 525, mobileScale: true })}
          `
        case 2:
          return css`
            width: 525px;
            ${calcFunction({ baseValue: 525, col: 1100 })}
          `
        case 3:
          return css`
            width: 175px;
            ${calcFunction({ baseValue: 175, col: 550 })}
          `
        case 4:
          return css`
            width: 525px;
            ${({ height, narrow, colspan }) => {
              if (!height) return ''
              if (height && colspan) return 'height: 100%;'
              if (height && narrow) return 'height: calc(525px / 2);'
              return 'height: 525px;'
            }}
          `
        default:
          if (height && colspan) {
            return css`
              width: 100%;
              height: 500px;
            `
          }
          return css`
            width: 100%;
          `
      }
    }}
  }

  @media (max-width: 550px) {
    ${({ height, colspan, columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 100%;
            ${calcFunction({ baseValue: 425, mobileScale: true })}
          `
        case 2:
          return css`
            width: 425px;
            ${calcFunction({ baseValue: 425 })}
          `
        case 3:
          return css`
            width: 175px;
            ${calcFunction({ baseValue: 175, col: 550 })}
          `
        case 4:
          return css`
            width: 425px;
            ${({ height, narrow, colspan }) => {
              if (!height) return ''
              if (height && colspan) return 'height: 100%;'
              if (height && narrow) return 'height: calc(425px / 2);'
              return 'height: 425px;'
            }}
          `
        default:
          if (height && colspan) {
            return css`
              width: 100%;
              height: 500px;
            `
          }
          return css`
            width: 100%;
          `
      }
    }}
  }

  @media (max-width: 450px) {
    ${({ height, colspan, columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 350px;
            ${calcFunction({ baseValue: 350, mobileScale: true })}
          `
        case 2:
          return css`
            width: 350px;
            ${calcFunction({ baseValue: 350 })}
          `
        case 3:
          return css`
            width: 175px;
            ${calcFunction({ baseValue: 175, col: 550 })}
          `
        case 4:
          return css`
            width: 350px;
            ${({ height, narrow, colspan }) => {
              if (!height) return ''
              if (height && colspan) return 'height: 100%;'
              if (height && narrow) return 'height: calc(350px / 2);'
              return 'height: 350px;'
            }}
          `
        default:
          if (height && colspan) {
            return css`
              width: 100%;
              height: 500px;
            `
          }
          return css`
            width: 100%;
          `
      }
    }}
  }

  @media (max-width: 320px) {
    ${({ height, colspan, columns }) => {
      switch (columns) {
        case 1:
          return css`
            width: 300px;
            ${calcFunction({ baseValue: 300 }, null, 300 / 2)}
          `
        case 2:
          return css`
            width: 300px;
            ${calcFunction({ baseValue: 300 })}
          `
        case 3:
          return css`
            width: 175px;
            ${calcFunction({ baseValue: 175, col: 550 })}
          `
        case 4:
          return css`
            width: 300px;
            ${({ height, narrow, colspan }) => {
              if (!height) return ''
              if (height && colspan) return 'height: 100%;'
              if (height && narrow) return 'height: calc(300px / 2);'
              return 'height: 300px;'
            }}
          `
        default:
          if (height && colspan) {
            return css`
              width: 100%;
              height: 500px;
            `
          }
          return css`
            width: 100%;
          `
      }
    }}
  }
`

export default ColumnContainer
