import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Text from '../../elements/text'
import Img from 'gatsby-image'

const Container = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 36px;
`

const Grid = styled.div`
  width: 100%;
  max-width: 945px;
`

const Row = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`

const Column = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`

const LogoSoup = ({ data }) => {
  const fields = data.allWordpressPage.edges[0].node.acf.logo_soup

  const logos = fields.images
    .map((img, i) => {
      return (
        <Column key={i}>
          <Img
            fluid={img.localFile.childImageSharp.fluid}
            style={{ height: 'auto', width: '120px' }}
          />
        </Column>
      )
    })
    .reduce((acc, col, idx) => {
      if (idx % 4 === 0) {
        acc.push([])
      }
      acc[acc.length - 1].push(col)
      return acc
    }, [])

  return (
    <Container>
      <TextContainer>
        <Text dark medium bold>
          {fields.headline}
        </Text>
      </TextContainer>
      <Grid>
        {logos.map((row, i) => (
          <Row key={i}>{row}</Row>
        ))}
      </Grid>
    </Container>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                logo_soup {
                  headline
                  images {
                    localFile {
                      childImageSharp {
                        fluid(quality: 100, maxWidth: 250) {
                          ...GatsbyImageSharpFluid
                          presentationWidth
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <LogoSoup data={data} />}
  />
)
