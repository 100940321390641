import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { SeoContext } from '../seo-context';
// title, description, twitter_image_alt_text, image
function SEO({ description, lang, meta, keywords, title, twitter_image_alt_text, image, url}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const favicon =
          data.allWordpressPage.edges[0].node.acf.icons.favicon.source_url
        const metaTitle = title || data.site.siteMetadata.title
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={metaTitle}
            link={[
              { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
            ]}
            meta={[
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:title`,
                content: metaTitle, // A concise title for the related content. [Maximum 70 characters.]
              },
              {
                property: `og:description`,
                content: metaDescription, // A description that concisely summarizes the content as appropriate for presentation within a Tweet. You should not re-use the title as the description or use this field to describe the general services provided by the website. [Maximum 200 characters.]
              },
              {
                property: `og:image`,
                content: image && image.source_url, // Needs to be 1200x630 pixels and an aspect ratio of 1.91:1, but adhere to Twitter's file size requirement of less than 1MB.
              },
              {
                property: `og:url`,
                content: url || data.site.siteMetadata.url, // We can calculate this
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:image:alt`,
                content: twitter_image_alt_text,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    allWordpressPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          acf {
            icons {
              favicon {
                source_url
              }
            }
          }
        }
      }
    }
  }
`
