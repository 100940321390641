import React, { useContext } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Text from '../../elements/text'
import Img from 'gatsby-image'
import { NavigationContext } from '../../../navigation-context'

const NavigationContainer = styled.div`
  position: absolute;
  background-color: #887001;
  ${props =>
    props.light
      ? css`
          background: white;
        `
      : ''}
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 20px;
  background: transparent;
`

const NavigationIcon = styled.div`
  height: 20px;
  width: 31px;
  margin: 0;
  z-index: 200;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  ${({ open }) => {
    if (open) {
      return css`
        & > .icon-bar {
          background-color: white;
          &:nth-child(1) {
            transform: rotate(45deg) translateY(4px) translateX(3px);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(-45deg) translateY(-6px) translateX(4px);
          }
        }
      `
    } else {
      return css`
        &:hover > .icon-bar {
          &:nth-child(1) {
            transform: translateY(-1px);
          }

          &:nth-child(2) {
            width: 15px;
            transform: translateX(3px);
          }

          &:nth-child(3) {
            transform: translateY(1px);
          }
        }
      `
    }
  }}
`
const NavigationIconBar = styled.div`
  height: 2px;
  width: 22px;
  background: white;
  ${props =>
    props.light
      ? css`
          background: #887001;
        `
      : ''}
  margin: 4px 0;
  border-radius: 3.5px;
  transition: 0.5s cubic-bezier(0.86, -0.91, 0.13, 2.26);
`

const NavigationOverlay = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  height: 1px;
  width: 1px;
  border-radius: 50%;
  transition: 0.5s ease-in-out;
  box-shadow: 0 0 0 0 #887001, 0 0 0 0 #887001;
  background: #887001;
  z-index: 100;

  ${({ open }) => {
    if (open) {
      return css`
        box-shadow: 0 0 0 150vw #887001, 0 0 0 150vh #887001;
        border-radius: 0;
      `
    }
  }}
`

const NavigationItemsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 200;
  opacity: 0;
  transition: 0.5s ease-in-out;
  text-align: center;
  pointer-events: none;
  ${({ open }) => {
    if (open) {
      return css`
        opacity: 1;
        pointer-events: all;
      `
    }
  }}
`

const NavigationList = styled.ul`
  margin: 0;
`

const NavigationItem = styled.li`
  list-style: none;
`

const NavigationLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`

const NavigationText = styled(Text)`
  will-change: opacity;
  transition: opacity 0.25s ease;
  &:hover {
    opacity: 0.5;
  }
`

const ContactText = styled(Text)`
  margin: 4px 0;
`

const AddressText = styled(Text)`
  margin: 0 3px;
`

const handleClick = (evt, location, setOpenStatus) => {
  evt.preventDefault()
  setOpenStatus(false)
  window.location = location
  // setTimeout(() => {
  // }, 500)
}

const Navigation = ({ data, style = {}, light}) => {
  const fields = data.navigationBar.edges[0].node.acf
  const { navigationOpen, toggleNavigation } = useContext(NavigationContext)

  const logo = light
  ? fields.light_logo.localFile.childImageSharp.fixed
  : fields.logo.localFile.childImageSharp.fixed
  return (
    <NavigationContainer light={light} style={{ ...style }}>
      <NavigationOverlay open={navigationOpen} />
      <NavigationItemsContainer open={navigationOpen}>
        <NavigationList>
          {fields.menu_items.menu_item.map((item, idx) => (
            <NavigationItem key={idx}>
              <NavigationLink
                onClick={evt =>
                  handleClick(evt, item.link_location, toggleNavigation)
                }
              >
                <NavigationText medium white bold>
                  {item.display_text}
                </NavigationText>
              </NavigationLink>
            </NavigationItem>
          ))}
        </NavigationList>
        <ContactText style={{ fontSize: '1.8rem' }} white bold>
          Contact
        </ContactText>
        <ContactText small white>
          {fields.contact.email}
        </ContactText>
        <ContactText small white>
          {fields.contact.phone_number}
        </ContactText>
        <div style={{margin: '4px'}}>
          <AddressText style={{ display: 'inline-block' }} small white>
            {fields.contact.street_address}
          </AddressText>
          <AddressText style={{ display: 'inline-block' }} small white>
            |
          </AddressText>
          <AddressText style={{ display: 'inline-block' }} small white>
            {fields.contact.city__state__zip}
          </AddressText>
        </div>
        <a href="/" style={{marginTop: '8px'}}>
          <Img
            loading="eager"
            fadeIn={false}
            placeholderStyle={{ display: 'none' }}
            fixed={fields.logo.localFile.childImageSharp.fixed}
          />
        </a>
      </NavigationItemsContainer>
      <a href="/">
        <Img
          loading="eager"
          fadeIn={false}
          placeholderStyle={{ display: 'none' }}
          fixed={logo}
        />
      </a>
      <NavigationIcon
        open={navigationOpen}
        onClick={() => {
          toggleNavigation()
        }}
      >
        <NavigationIconBar light={light} className="icon-bar" />
        <NavigationIconBar light={light} className="icon-bar" />
        <NavigationIconBar light={light} className="icon-bar" />
      </NavigationIcon>
    </NavigationContainer>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        navigationBar: allWordpressPage(
          filter: { slug: { eq: "navigation-bar" } }
        ) {
          edges {
            node {
              acf {
                menu_icon {
                  url {
                    source_url
                  }
                }
                logo {
                  localFile {
                    childImageSharp {
                      fixed(quality: 100, width: 178) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
                light_logo {
                  localFile {
                    childImageSharp {
                      fixed(quality: 100, width: 178) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
                menu_items {
                  menu_item {
                    display_text
                    link_location
                  }
                }
                contact {
                  email
                  phone_number
                  street_address
                  city__state__zip
                  logo {
                    localFile {
                      childImageSharp {
                        fixed(quality: 100, width: 178) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Navigation data={data} {...props} />}
  />
)
