import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Text from '../../elements/text'
import Img from 'gatsby-image'
import Carousel from '../../elements/carousel'
import media from '../../../utils/media'

const Container = styled.div`
  margin-top: 100px;
`

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 36px;
`
const AboutUsText = styled(Text)`
  ${media.phone`
    font-size: 2.5em;
  `}
`

const AboutUs = ({ data }) => {
  const fields = data.allWordpressPage.edges[0].node.acf.about_us_carousel
  return (
    <Container>
      <TextContainer>
        <AboutUsText dark medium bold>
          {fields.headline}
        </AboutUsText>
      </TextContainer>
      <Carousel settings={{ centerMode: false }} height="417px">
        {fields.gallery.map(img => {
          return (
            <div key={img.id}>
              <Img fixed={img.localFile.childImageSharp.fixed} />
            </div>
          )
        })}
      </Carousel>
    </Container>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                about_us_carousel {
                  headline
                  gallery {
                    id
                    localFile {
                      childImageSharp {
                        fixed(quality: 100, height: 417) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <AboutUs data={data} />}
  />
)
