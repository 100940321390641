import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Text from '../../elements/text'
import Img from 'gatsby-image'
import media from '../../../utils/media'

const PostContainer = styled.div`
  padding: 40px;
  padding-top: 100px;
  ${media.phone`
    padding-top: 100px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
  `};
`

const HeroImageWrapper = styled.div`
  height: 400px;
  width: 100%;
  max-width: 900px;
  margin: 20px auto;
  object-fit: cover;
  ${media.phone`
    height: 200px;
  `};
  @media (min-width: 1550px) {
    height: 500px;
    max-width: 1300px;
  }
`

const HeroImageStyle = {
  height: '100%',
  width: '100%',
  // maxWidth: '900px',
  margin: '0 auto',
}

const HeaderText = styled(Text)`
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  @media (min-width: 1550px) {
    max-width: 1200px;
  }
`

const WPContent = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 0.8rem;
  line-height: 1.6;
  max-width: 800px;
  padding: 0 20px;
  margin: 0 auto;
  color: ${({ theme }) => theme.fontColorDark};
  h1 {
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme }) => theme.fontColorDark};
  }

  @media (min-width: 1550px) {
    max-width: 1200px;
  }
`
const ReadMoreContainer = styled.div`
  max-width: 750px;
  margin: 0 auto;
  padding: 32px;
  margin-top: -20px;
  padding-top: 0;
  display: flex;
  justify-content: flex-end;
`

const ReadMoreLink = styled.a`
  text-decoration: none;
  display: inline-block;
`
const TitleIconImageWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
`

const ReadMore = ({ node }) => {
  return (
    <ReadMoreContainer>
      <ReadMoreLink href={node.path}>
        <Text small gold bold>
          Read More...
        </Text>
      </ReadMoreLink>
    </ReadMoreContainer>
  )
}

const PostContent = props => {
  const { data, node, redacted } = props
  const headerIcon =
    data.blogPostPage.edges[0].node.acf.media.title_icon.localFile
      .childImageSharp.fixed
  return (
    <PostContainer>
      <HeaderText dark medium>
        {node.title}
      </HeaderText>
      <TitleIconImageWrapper>
        <Img fixed={headerIcon} />
      </TitleIconImageWrapper>
      <HeroImageWrapper>
        <Img
          fluid={node.acf.hero.localFile.childImageSharp.fluid}
          style={HeroImageStyle}
        />
      </HeroImageWrapper>
      <WPContent
        className="content"
        redacted={redacted}
        dangerouslySetInnerHTML={{
          __html: redacted ? node.acf.snippet : node.content,
        }}
      />
      {redacted ? <ReadMore node={node} /> : null}
    </PostContainer>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        blogPostPage: allWordpressPage(filter: { slug: { eq: "blog-post" } }) {
          edges {
            node {
              acf {
                media {
                  title_icon {
                    localFile {
                      childImageSharp {
                        fixed(quality: 100, width: 250) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <PostContent data={data} {...props} />}
  />
)
