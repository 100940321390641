import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import './layout.css'
import styled, { ThemeProvider } from 'styled-components'
import '../../node_modules/slick-carousel/slick/slick.css'
import '../../node_modules/slick-carousel/slick/slick-theme.css'
import SEO from '../components/seo'
import { NavigationContext } from '../navigation-context'
import { SeoContext } from '../seo-context'

const mainTheme = {
  primaryColor: '#887001',
  bgColor: '#1b1b1b',
  blackColor: '#1c1c1c',
  lightGrayColor: '#E0E0E0',
  fontFamily: 'Avenir',
  fontWeightNormal: 'normal',
  fontWeightBold: 900,
  fontSizeSmall: '1.2em',
  fontSizeNormal: '1.6em',
  fontSizeMedium: '2.8em',
  fontSizeLarge: '4.8em',
  fontSizeExtraLarge: '6em',
  fontColorNormal: 'white',
  fontColorDark: '#555555',
  fontColorGrey: '#A0A0A0;',
  fontColorBlack: '#2A2A2A;',
  fontColorGold: '#715E05;',
}

const LayoutWrapper = styled.div`
  height: ${({ navigationOpen }) => (navigationOpen ? '100vh' : '')};
  overflow: ${({ navigationOpen }) => (navigationOpen ? 'hidden' : '')};
`

const Layout = ({ children }) => {
  const [navigationOpen, setNavigationOpen] = useState(false)
  const [seoFields, setSeoFields] = useState({})
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <SeoContext.Provider
          value={{
            fields: seoFields,
            updateFields: fields => {
              setSeoFields(fields)
            },
          }}
        >
          <NavigationContext.Provider
            value={{
              navigationOpen: navigationOpen,
              toggleNavigation: () => {
                setNavigationOpen(!navigationOpen)
              },
            }}
          >
            <ThemeProvider theme={mainTheme}>
              <LayoutWrapper navigationOpen={navigationOpen}>
                <SEO {...seoFields}/>
                {children}
              </LayoutWrapper>
            </ThemeProvider>
          </NavigationContext.Provider>
        </SeoContext.Provider>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
