import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const HeroImageStyle = {
  height: '406px',
  maxWidth: '1000%',
  width: '100%',
}

const HeroOverlay = styled.div`
  opacity: 0.35;
  background-color: #323232;
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: ${(props) => props.overlay ? 'block' : 'none'}
`

const HeroContainer = styled.div`
  position: relative;
`

const Hero = props => {
  const fields = props.data.allWordpressPage.edges[0].node.acf.hero
  return (
    <HeroContainer>
      <HeroOverlay overlay={fields.with_overlay}></HeroOverlay>
      <Img
        fluid={fields.image.localFile.childImageSharp.fluid}
        style={HeroImageStyle}
      />
    </HeroContainer>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "blog" } }) {
          edges {
            node {
              acf {
                hero {
                  image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 100, maxHeight: 1000) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  with_overlay
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Hero data={data} {...props} />}
  />
)
