import React from 'react'
import styled, { css } from 'styled-components'
import Text from '../../elements/text'
import ColumnContainer from '../column-container'

const Container = styled(ColumnContainer)`
    flex: 1;
    display: flex;
    ${({ vertical }) => {
      if (!vertical) return '';

      return css`
        flex-direction: column;
        align-items: center;
      `
    }}

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
  `

  const StatGroupContainer = styled.div`
    text-align: center;
    ${({ vertical }) => {
      if (!vertical) {
        return ''
      }

      return css`
        margin-bottom: 45px;
        :last-child {
          margin-bottom: 0;
        }
      `
    }}

    @media (max-width: 640px) {
      margin-bottom: 45px;
    }
  `

  const StatGroupHeadlineText = styled(Text)`
    line-height: 1em;
    @media (min-width: 1500px) {
      font-size: 8em;
    }
  `

  const StatGroupFactText = styled(Text)`
    @media (min-width: 1500px) {
      font-size: 2em;
    }
  `

  const StatGroup = ({ headline, sub_stats, vertical }) => {
    return (
      <StatGroupContainer vertical={vertical}>
        <StatGroupHeadlineText black bold large>
          {headline}
        </StatGroupHeadlineText>
        {sub_stats.map(({ text }, idx) => (
          <StatGroupFactText small black key={idx}>
            {text}
          </StatGroupFactText>
        ))}
      </StatGroupContainer>
    )
  }

const StatGroups = ({ column, columns }) => {
  const vertical = columns > 1

  return (
    <Container vertical={vertical}>
      {column.stat_group.map((group, idx) => (
        <StatGroup key={idx} {...group} vertical={vertical} />
      ))}
    </Container>
  )
}

export default StatGroups
