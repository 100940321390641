import React from 'react'
import styled from 'styled-components'

const SliderContainer = styled.div.attrs({
  style: props => {
    return { transform: `translateX(${props.scrollPosition}%)` }
  },
})`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${props => props.bgColor || props.theme.primaryColor};
  opacity: 0.8;
  z-index: 10;
  transition: transform 0.1s linear;
`
class Slider extends React.Component {
  state = {
    scrollPosition: -50,
  }

  heroContainerRef = React.createRef()

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const refHeight = this.props.containerRef.current.offsetHeight
    const offset = (window.scrollY / refHeight) * this.props.offset
    let scroll = Math.round(-50 + offset)

    if (scroll > 0) {
      scroll = 0
    } else if (scroll < -50) {
      scroll = -50
    }

    this.setState({ scrollPosition: scroll })
  }

  render() {
    return (
      <SliderContainer
        bgColor={this.props.bgColor}
        scrollPosition={this.state.scrollPosition}
      />
    )
  }
}

export default Slider
