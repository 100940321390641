import React from 'react'
import styled from 'styled-components'
import Text from '../../elements/text'
import media from '../../../utils/media'

const CategoriesColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const Category = styled.div`
  padding: 12px 30px;
  ${media.ourServicesSmall`
    padding: 12px 0;
  `}
`

const CategoryHeader = styled(Text)`
  margin-bottom: 6px;
  white-space: nowrap;
`

const SubCatagoriesContainer = styled.div`
  ${media.phone`
    &.out {
      display: none;
    }
  `}
`

const SubCategory = styled(Text)`
  white-space: nowrap;
`

class Categories extends React.Component {
  state = { out: [] }

  handleClick = idx => {
    // const index = this.state.out.indexOf(idx)
    // let out = this.state.out
    // if (index >= 0) {
    //   out = [...out.slice(0, index), ...out.slice(index + 1)]
    // } else {
    //   out = [...out, idx]
    // }
    // this.setState({ out })
  }
  styledCategories = categories => {
    return categories.map((cat, idx) => {
      return (
        <Category key={idx} onClick={() => this.handleClick(idx)}>
          <CategoryHeader bold>{cat.name}</CategoryHeader>
          <SubCatagoriesContainer
            className={this.state.out.indexOf(idx) >= 0 ? 'out' : 'in'}
          >
            {cat.sub_categories.map((subCat, idx) => (
              <SubCategory key={idx} small light>
                {subCat.name}
              </SubCategory>
            ))}
          </SubCatagoriesContainer>
        </Category>
      )
    })
  }
  render() {
    const categoryColumns = this.styledCategories(this.props.categories)
      .reduce(
        (acc, cat, idx) => {
          acc[idx % 2 === 0 ? 0 : 1].push(cat)
          return acc
        },
        [[], []]
      )
      .map((grouped, idx) => (
        <CategoriesColumn key={idx}>{grouped}</CategoriesColumn>
      ))

    return <>{categoryColumns}</>
  }
}

export default Categories
