import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Text from '../../elements/text'
import Categories from './categories'
import media from '../../../utils/media'

const Container = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: center;
`

const Layout = styled.div`
  width: 100%;
  border-radius: 24px;
  padding: 43px;
  display: flex;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.bgColor};
  max-width: 1168px;
  ${media.ourServicesSmall`
    flex-direction: column;
    padding: 20px;
  `}
`

const InfoSection = styled.div`
  max-width: 500px;
`

const CategoriesSection = styled.div`
  max-width: 500px;
  display: flex;
  justify-content: center;
  ${media.ourServicesSmall`
    flex-direction: column;
  `}
`

const HeadlineTextContainer = styled.div`
  margin: 20px 0;
`

const HeadlineText = styled(Text)`
  line-height: 1;
  ${media.ourServicesTablet`
    font-size: ${({ theme }) => theme.fontSizeLarge}
  `}
`

const StyledLogo = styled.div`
  height: 100px;
  width: 100%;
  ${media.ourServicesTablet`
    height: 75px;
  `}
`

// const ArrowLink = styled.a`
//   text-decoration: none;
//   display: flex;
//   align-items: center;
// `
const Services = ({ data }) => {
  const fields = data.allWordpressPage.edges[0].node.acf.our_services
  return (
    <Container>
      <Layout>
        <InfoSection>
          <StyledLogo>
            <img
              src={fields.logo.source_url}
              style={{ height: '100%', width: 'auto' }}
              alt="HuntGather Logo"
            />
          </StyledLogo>
          <HeadlineTextContainer>
            {fields.headline.map((h, i) => (
              <HeadlineText bold xLarge key={i}>
                {h.text}
              </HeadlineText>
            ))}
          </HeadlineTextContainer>
          {/* <ArrowLink href={fields.cta_link}>
            <Text bold style={{ width: '175px', lineHeight: 1 }}>
              {fields.cta_text}
            </Text>
          </ArrowLink> */}
        </InfoSection>
        <CategoriesSection>
          <Categories categories={fields.categories} />
        </CategoriesSection>
      </Layout>
    </Container>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                our_services {
                  logo {
                    source_url
                  }
                  headline {
                    text
                  }
                  cta_text
                  cta_link
                  categories {
                    name
                    sub_categories {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Services data={data} {...props} />}
  />
)
