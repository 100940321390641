import React from 'react'
import Layout from '../../layout'
import styled from 'styled-components'
import Navigation from '../../elements/navigation'
import Footer from '../../footer'
import FooterBlocks from '../../elements/footer-blocks'
import Row from '../row'
import PostNavigation from '../../post-page/post-navigation'
import postPath from '../../../utils/post-path'


const Container = styled.div`
  max-width: 914px;
  margin: 0 auto;
  padding: 81px 21px;

  @media (min-width: 1100px) {
    max-width: 1000px;
  }

  @media (min-width: 1500px) {
    max-width: 1200px;
  }
`

const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
`

const Show = ({ node, resources, siblingResources, resourcePath, seoFields }) => {
  const position = siblingResources.findIndex(({id}) => id === node.wordpress_id)

  let prev = (siblingResources[position - 1] || siblingResources[siblingResources.length - 1]) || {}
  let next =(siblingResources[position + 1] || siblingResources[0]) || {}

  prev = {...prev, path: postPath(resourcePath, prev)}
  next = {...next, path: postPath(resourcePath, next)}

  return (
    <Layout>
      <Navigation light={true} />
      <Container>
        {resources.map(
          (row, idx) => (
            <Row row={row} key={idx} seoFields={seoFields} />
          )
        )}
      </Container>
      <NavigationContainer>
        <PostNavigation previous {...prev} />
        <PostNavigation {...next} />
      </NavigationContainer>
      <FooterBlocks />
      <Footer style={{ marginTop: 0 }} />
    </Layout>
  )
}

export default Show
