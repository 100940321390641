import React from 'react'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`

const FadeContainer = styled.div`
  &.fade-in {
    animation: ${fadeIn} ${props => props.duration || 5}s ease;
    animation-fill-mode: forwards;
  }

  &.fade-out {
    animation: ${fadeOut} ${props => props.duration || 5}s ease;
    animation-fill-mode: forwards;
  }
`

class Fader extends React.Component {
  state = {
    animationIndex: 0,
    inProgress: true,
  }

  componentDidMount = () => {
    const delay = this.props.initialDelay || 0
    const duration = this.props.duration || 5
    this.timeout1 = setTimeout(() => {
      this.setState({ inProgress: false })
    }, (duration + delay) * 1000)
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout1)
    clearTimeout(this.timeout2)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.inProgress === true && prevState.inProgress === false) {
      const duration = this.props.duration || 5

      this.timeout1 = setTimeout(() => {
        this.setState({ inProgress: false })
      }, duration * 1000)
    } else if (
      this.state.inProgress === false &&
      prevState.inProgress === true
    ) {
      const delay = this.props.intervalDelay || 0
      let animationIndex = this.state.animationIndex + 1
      const children = this.props.children
      this.timeout2 = setTimeout(() => {
        animationIndex = animationIndex >= children.length ? 0 : animationIndex
        this.setState({ inProgress: true, animationIndex })
      }, delay * 1000)
    }
  }

  render() {
    return (
      <FadeContainer
        className={this.state.inProgress ? 'fade-in' : 'fade-out'}
        duration={this.props.duration}
      >
        {this.props.children[this.state.animationIndex]}
      </FadeContainer>
    )
  }
}

export default Fader
