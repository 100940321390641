import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'

const Container = styled.div`
  .slick-list {
    ${props => (props.height ? `height: ${props.height};` : '')}
  }
  .slick-dots {
    li {
      height: initial;
      width: initial;

      &.slick-active {
        div {
          background-color: ${({ theme }) => theme.primaryColor};
        }
      }
    }
  }
`

const Dot = styled.div`
  height: 4px;
  width: 34px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.lightGrayColor};
`

const Carousel = props => {
  const propsSettings = props.settings || {}
  const settings = {
    className: 'slider variable-width',
    dots: true,
    dotsClass: 'slick-dots',
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    customPaging: () => <Dot />,
    ...propsSettings,
  }

  return (
    <Container height={props.height}>
      <Slider {...settings}>{props.children}</Slider>
    </Container>
  )
}

export default Carousel
