import React from 'react'
import styled from 'styled-components'
import Text from '../../elements/text'

const TextSectionContainer = styled.div``

const TextGroup = styled.div`
  margin: 15px 0;
`

const StyledTextGroup = styled(Text)`
  font-size: 1.4em;
  @media (min-width: 1500px) {
    font-size: 1.8em;
  }
`
const SectionHeadline = styled(Text)`
  font-size: 2.8em;
  margin: 0;
  margin-bottom: 15px;

  @media (min-width: 1500px) {
    font-size: 3.75em;
  }
`

const SubSectionHeadline = styled(Text)`
  font-size: 2.25em;
  @media (min-width: 1500px) {
    font-size: 3.5em;
  }
`

const TextSection = ({
  section_headline,
  sub_section_headline,
  text_group,
}) => {
  return (
    <TextSectionContainer>
      <SectionHeadline medium black bold>
        {section_headline}
      </SectionHeadline>
      <SubSectionHeadline medium black>
        {sub_section_headline}
      </SubSectionHeadline>
      {text_group.map((g, i) => (
        <TextGroup>
          <StyledTextGroup small black bold>
            {g.headline}
          </StyledTextGroup>
          <StyledTextGroup small black>
            {g.body}
          </StyledTextGroup>
        </TextGroup>
      ))}
    </TextSectionContainer>
  )
}

export default TextSection
