// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-post-index-index-js": () => import("./../src/components/post-index/index.js" /* webpackChunkName: "component---src-components-post-index-index-js" */),
  "component---src-components-post-page-index-js": () => import("./../src/components/post-page/index.js" /* webpackChunkName: "component---src-components-post-page-index-js" */),
  "component---src-components-works-projects-show-js": () => import("./../src/components/works/projects/show.js" /* webpackChunkName: "component---src-components-works-projects-show-js" */),
  "component---src-components-works-index-js": () => import("./../src/components/works/index.js" /* webpackChunkName: "component---src-components-works-index-js" */),
  "component---src-components-works-case-studies-show-js": () => import("./../src/components/works/case_studies/show.js" /* webpackChunkName: "component---src-components-works-case-studies-show-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

