import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Text from '../../elements/text'

const NavigationContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${props => (props.previous ? 'flex-end' : 'flex-start')};
  ${props =>
    props.previous
      ? css`
          margin-right: 60px;
        `
      : css`
          margin-left: 60px;
        `}
`
const NavigationLink = styled.a`
  text-decoration: none;
  display: inline-block;
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.previous ? 'flex-end' : 'flex-start')};
  white-space: wrap;
  text-align: ${props => (props.previous ? 'right' : 'left')};
  max-width: 338px;
`

const NavigationIcon = styled.img`
  height: 38px;
  width: auto;
  display: block;
  margin-bottom: 11px;
  transform: rotate(${props => (props.previous ? '180deg' : '0')});
  max-width: 338px;
  text-align: right;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`

const PostNavigation = ({ node, previous, path, title }) => {
  const iconSrc = node.acf.media.pager_icons.url.source_url
  const disabled = !title
  return (
    <NavigationContainer previous={previous}>
      <NavigationLink previous={previous} href={path}>
        <NavigationIcon disabled={disabled} previous={previous} src={iconSrc} alt="" />
        <Text dark>{title}</Text>
      </NavigationLink>
    </NavigationContainer>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "blog-post" } }) {
          edges {
            node {
              acf {
                media {
                  pager_icons {
                    url {
                      source_url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <PostNavigation node={data.allWordpressPage.edges[0].node} {...props} />
    )}
  />
)
