import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Text from '../../elements/text'
import media from '../../../utils/media'
import Img from 'gatsby-image'
import Fader from '../../elements/fader'

const HeroContainer = styled.div`
  position: relative;
  height: 100vh;
  background-color: ${props => props.theme.bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  ${media.phone`
    height: 510px;
  `}
`

const HeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  width: 100%;
`

const HeroText = styled(Text)`
  ${media.phone`
    font-size: 2.4em;
  `}
`

const RollingTextContainer = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
`

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  ${props =>
    props.leftSide
      ? css`
          justify-content: flex-end;
        `
      : ''}
  p {
    ${props =>
      props.width
        ? css`
            width: ${props.width}px;
          `
        : ''}
  }
`

const HeroVideo = styled.video`
  position: absolute;
  // height: 100%;
  // width: auto;
`
class Hero extends React.Component {
  state = {
    animationIndex: 0,
    animationStage: 0,
    shouldPlayVideo: false,
  }

  heroContainerRef = React.createRef()

  componentDidMount = () => {
    this.shouldPlayVideo()
  }

  shouldPlayVideo = () => {
    const shouldPlay = window.innerWidth > 576
    if (shouldPlay === this.state.shouldPlayVideo) {
      return
    }
    this.setState({ shouldPlayVideo: shouldPlay })
  }

  render() {
    const fields = this.props.data.allWordpressPage.edges[0].node.acf.hero
    const currentText = fields.dynamic_headlines[0].headline_group
    return (
      <HeroContainer ref={this.heroContainerRef}>
        {this.state.shouldPlayVideo ? (
          <HeroVideo
            autoPlay
            muted
            loop
            src={fields.video.source_url}
            ref={this.videoRef}
          />
        ) : (
          <Img
            fixed={fields.video_placeholder.localFile.childImageSharp.fixed}
            style={{
              height: '510px',
              maxWidth: '1000%',
              width: '100%',
              position: 'absolute',
            }}
          />
        )}
        <HeroTextContainer>
          <HeroText large bold>
            {fields.static_headline}
          </HeroText>
          <RollingTextContainer>
            <TextContainer leftSide={true}>
              <Fader duration={2.5} initialDelay={2.5} intervalDelay={2.5}>
                {currentText.map((t, i) => (
                  <HeroText key={i} large bold style={{ opacity: 1 }}>
                    {t.left_text}
                  </HeroText>
                ))}
              </Fader>
            </TextContainer>
            <div>
              <HeroText large bold style={{ margin: '0 10px' }}>
                &
              </HeroText>
            </div>
            <TextContainer>
              <Fader duration={2.5} initialDelay={0} intervalDelay={2.5}>
                {currentText.map((t, i) => (
                  <HeroText key={i} large bold style={{ opacity: 1 }}>
                    {t.right_text}
                    <span style={{ color: '#887001' }}>.</span>
                  </HeroText>
                ))}
              </Fader>
            </TextContainer>
          </RollingTextContainer>
        </HeroTextContainer>
      </HeroContainer>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                hero {
                  static_headline
                  dynamic_headlines {
                    headline_group {
                      left_text
                      right_text
                    }
                  }
                  logo {
                    localFile {
                      childImageSharp {
                        fixed(quality: 100, width: 178) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  video {
                    source_url
                  }
                  video_placeholder {
                    localFile {
                      childImageSharp {
                        fixed(quality: 100, height: 510) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Hero data={data} {...props} />}
  />
)
