import React from 'react'
import { Parallax } from 'react-parallax'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Text from '../../elements/text'
import media from '../../../utils/media'
import Img from 'gatsby-image'

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 845px;
  margin: auto;
  padding: 200px 70px 100px 70px;
  ${media.tablet`
    max-width: 100%;
    padding: 40px;
  `}
  ${media.phone`
    max-width: 345px;
    padding: 25px;
  `}
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const QuoteText = styled(Text)`
  text-align: center;
  ${media.phone`
    font-size: 2em;
  `}
`

const ParallaxSection = ({ data }) => {
  const fields = data.allWordpressPage.edges[0].node.acf.parallax
  return (
    <div>
      {/* -----basic config-----*/}
      <Parallax
        strength={100}
        bgImage={fields.background_image.source_url}
        bgImageStyle={{ height: '90%', width: 'auto', marginTop: '75px' }}
        bgStyle={{ backgroundPosition: 'bottom' }}
      >
        <TextContainer>
          <QuoteText dark medium bold>
            {fields.quote}
          </QuoteText>
          <LogoContainer>
            <Img fadeIn={false} fixed={fields.logo.localFile.childImageSharp.fixed} />
            <QuoteText
              gold
              medium
              bold
              style={{ marginLeft: '10px', whiteSpace: 'nowrap' }}
            >
              {fields.trailing_text}
            </QuoteText>
          </LogoContainer>
        </TextContainer>
      </Parallax>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                parallax {
                  background_image {
                    source_url
                  }
                  quote
                  logo {
                    localFile {
                      childImageSharp {
                        fixed(quality: 100, width: 138) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  trailing_text
                }
              }
            }
          }
        }
      }
    `}
    render={data => <ParallaxSection data={data} {...props} />}
  />
)
