import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import ShowContent from '../show/index'
import SeoFields from '../../shareable_seo'
import { SeoContext } from '../../../seo-context'

const Show = ({ data, pageContext: { projects }, location: { href } }) => {
  const node = data.project.edges[0].node
  const { updateFields } = useContext(SeoContext)
  updateFields({ ...node.acf.seo_fields, url: href })

  return (
    <>
      <SeoFields fields={node.acf.seo_fields} url={href} />
      <ShowContent
        node={node}
        resources={node.acf.page_content_project}
        siblingResources={projects}
        resourcePath="/projects/"
      />
    </>
  )
}

export const query = graphql`
  query ProjectShowPageQuery($id: String) {
    project: allWordpressWpProject(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          wordpress_id
          title
          content
          acf {
            seo_fields {
              title
              twitter_image_alt_text
              description
              image {
                source_url
              }
            }
            page_content_project {
              column {
                acf_fc_layout
                text_group {
                  body
                  headline
                }
                image {
                  localFile {
                    childImageSharp {
                      fixed(quality: 100, height: 395, width: 395) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                  source_url
                }
                stat_group {
                  headline
                  sub_stats {
                    text
                  }
                }
                section_headline
                sub_section_headline
                quote
                attributed_to
                align
                colspan
                video_id
                image_text_line_1
                image_text_line_2
                image_text_line_3
                narrow
                forced_width
              }
            }
          }
        }
      }
    }
  }
`

export default Show
