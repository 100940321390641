import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Text from '../../elements/text'
import LinkIcon from '../../elements/link-icon'
import Img from 'gatsby-image'
import media from '../../../utils/media'

const Wrapper = styled.div`
  padding: 0 64px;
  display: flex;
  justify-content: center;
  ${media.contactUsSmall`
    padding: 0 30px;
  `}
`

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-width: 945px;
  ${media.contactUsSmall`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`

const LeftContainer = styled.div`
  margin-right: 100px;
  ${media.tablet`
    margin-right: 20px;
  `}

  ${media.contactUsSmall`
    margin: 0;
  `}
`
const RightContainer = styled.div`
  max-width: 525px;
`
const ContactsContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${media.contactUsSmall`
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `}
`

const LogoContainer = styled.div`
  height: 100px;
  ${media.contactUsSmall`
    ${props => (props.spacer ? 'height: 20px' : '')}
  `}
`

const HeadlineText = styled(Text)`
  white-space: nowrap;
  line-height: 1;
`

const Link = styled.a`
  text-decoration: none;
`

const SubHeadlineText = styled(Text)`
  margin-bottom: 50px;
  ${media.contactUsSmall`
    margin-bottom: 20px;
  `}
`

const ContactContainer = styled.div`
  ${media.contactUsSmall`
    margin-bottom: 20px;
  `}
`

const ContactSpacer = styled.div`
  flex: 1;
  max-width: 100px;
  min-width: 25px;
`

const DescriptionText = styled(Text)`
  white-space: nowrap;
`

const ArrowLink = styled(Link)`
  display: flex;
  align-items: center;
`
const StyledLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`

const GeneralContactText = styled(Text)`
  margin-top: 20px;
`

const ContactUs = ({ data }) => {
  const fields = data.allWordpressPage.edges[0].node.acf.contact_us

  return (
    <Wrapper>
      <Container>
        <LeftContainer>
          <LogoContainer>
            <Img
              fixed={fields.logo.localFile.childImageSharp.fixed}
              style={{ height: '65px', width: '121px' }}
            />
          </LogoContainer>
          {fields.headline.map((headline, i) => (
            <HeadlineText black bold xLarge key={i}>
              {headline.text}
            </HeadlineText>
          ))}
          <GeneralContactText black bold>
            {fields.general_contact}
          </GeneralContactText>
          <Link href={`mailto:${fields.email}`}>
            <Text gold bold>
              {fields.email}
            </Text>
          </Link>
        </LeftContainer>
        <RightContainer>
          <LogoContainer spacer />
          <SubHeadlineText bold black>
            {fields.sub_headline}
          </SubHeadlineText>
          <ContactsContainer>
            {fields.primary_contacts.map((c, i) => (
              <React.Fragment key={i}>
                <ContactContainer>
                  <ArrowLink href={`mailto:${c.email}`}>
                    <Text black bold>
                      {c.name}
                    </Text>
                    <StyledLinkWrapper>
                      <LinkIcon />
                    </StyledLinkWrapper>
                  </ArrowLink>
                  <DescriptionText black small>
                    {c.description}
                  </DescriptionText>
                  <Text black small>
                    {c.email}
                  </Text>
                </ContactContainer>
                <ContactSpacer />
              </React.Fragment>
            ))}
          </ContactsContainer>
        </RightContainer>
      </Container>
    </Wrapper>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                contact_us {
                  logo {
                    localFile {
                      childImageSharp {
                        fixed(quality: 100, width: 121) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  headline {
                    text
                  }
                  general_contact
                  email
                  sub_headline
                  primary_contacts {
                    name
                    email
                    description
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <ContactUs data={data} />}
  />
)
