import React from 'react'
import StatGroups from '../stat-groups'
import ColumnContainer from '../column-container'
import TextSection from '../text-section'
import PullQuote from '../pull-quote'
import ImageBlock from '../image-block'
import YoutubeVideo from '../youtube-video'
import VimeoVideo from '../vimeo-video'

const ColumnContent = ({ column, rowColumns }) => {
  switch (column.acf_fc_layout) {
    case 'stat_section':
      return (
        <ColumnContainer columns={rowColumns} justifyContent="space-around">
          <StatGroups column={column} columns={rowColumns} />
        </ColumnContainer>
      )
    case 'youtube_video':
      return (
        <ColumnContainer height columns={rowColumns}>
          <YoutubeVideo column={column} />
        </ColumnContainer>
      )
    case 'vimeo_video':
      return (
        <ColumnContainer height columns={rowColumns}>
          <VimeoVideo column={column} />
        </ColumnContainer>
      )
    case 'image_block':
      return (
        <ColumnContainer
          columns={column.forced_width !== 'normal' ? Number(column.forced_width) : rowColumns}
          height
          narrow={column.narrow}
          colspan={column.colspan === 'double'}
        >
          <ImageBlock {...column} />
        </ColumnContainer>
      )
    case 'text_section':
      return (
        <ColumnContainer columns={rowColumns}>
          <TextSection {...column} />
        </ColumnContainer>
      )
    case 'pull_quote':
      return (
        <ColumnContainer columns={rowColumns}>
          <PullQuote {...column} />
        </ColumnContainer>
      )
    default:
      return ''
  }
}

const Column = ({ column, rowColumns }) => {
  return <ColumnContent column={column} rowColumns={rowColumns} />
}

export default Column
