import React from 'react'
import styled from 'styled-components'
import Text from '../../elements/text'

const PullQuoteContainer = styled.div`
  text-align: ${({ align }) => align};
`

const PullQuoteText = styled(Text)`
  font-size: 2em;
`

const AttributedToContainer = styled.div`
  text-align: right;
`


const PullQuote = ({ attributed_to, quote, align }) => {
  return (
    <PullQuoteContainer align={align}>
      <PullQuoteText medium bold gold>
        {quote}
        <AttributedToContainer>
          {attributed_to}
        </AttributedToContainer>
      </PullQuoteText>
    </PullQuoteContainer>
  )
}

export default PullQuote
