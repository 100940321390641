import React from 'react'
import Helmet from 'react-helmet'

export default ({ fields, url }) => {
  const { title, description, twitter_image_alt_text, image } = fields || {}
  return (
    <Helmet
      meta={[
        {
          property: `og:title`,
          content: title, // A concise title for the related content. [Maximum 70 characters.]
        },
        {
          property: `og:description`,
          content: description, // A description that concisely summarizes the content as appropriate for presentation within a Tweet. You should not re-use the title as the description or use this field to describe the general services provided by the website. [Maximum 200 characters.]
        },
        {
          property: `og:image`,
          content: image && image.source_url, // Needs to be 1200x630 pixels and an aspect ratio of 1.91:1, but adhere to Twitter's file size requirement of less than 1MB.
        },
        {
          property: `og:url`,
          content: url, // We can calculate this
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image:alt`,
          content: twitter_image_alt_text,
        },
      ]}
    />
  )
}
