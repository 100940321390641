import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Text from '../../elements/text'
import media from '../../../utils/media'

const BlocksContainer = styled.div`
  display: flex;
  ${media.phone`
    flex-direction: column;
    height: 800px;
  `}
`

const FooterBlock = styled.div`
  flex: 1;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  color: ${({ theme, even }) =>
    even ? theme.fontColorNormal : theme.fontColorDark};
  background: ${({ theme, even }) =>
    even ? theme.fontColorGold : theme.lightGrayColor};
`

const FooterBlockText = styled(Text)`
  line-height: 0.85;
`

const ContentContainer = styled.div``

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme, even }) =>
    even ? theme.fontColorNormal : theme.fontColorDark};
  font-family: ${theme => theme.fontFamily};
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  height: 40px;
  width: auto;
  margin: 0;
`

const LinkIcon = styled.img`
  height: 15px;
  width: auto;
  margin: 0;
  margin-left: 10px;
`

const FooterLinkIcon = ({ blocks, block }) => {
  if (block.include_icon) {
    return <LinkIcon src={blocks.link_icon.url.source_url} />
  }

  return null
}

const FooterBlocks = ({ data }) => {
  const blocks = data.allWordpressPage.edges[0].node.acf.footer_blocks
  return (
    <BlocksContainer>
      {' '}
      {blocks.footer_block.map((block, idx) => {
        const even = idx % 2 === 0
        return (
          <FooterBlock key={idx} even={even}>
            <ContentContainer>
              <Logo src={block.logo.source_url} />
              {block.text.map(({ line }, idx) => (
                <FooterBlockText key={idx} bold large black={!even}>
                  {line}
                </FooterBlockText>
              ))}
              <StyledLink even={even} href={block.link.url}>
                <Text small bold black={!even}>
                  {block.link.text}
                </Text>{' '}
                <FooterLinkIcon blocks={blocks} block={block} />
              </StyledLink>
            </ContentContainer>
          </FooterBlock>
        )
      })}
    </BlocksContainer>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogPageQuery {
        allWordpressPage(filter: { slug: { eq: "blog-post" } }) {
          edges {
            node {
              acf {
                footer_blocks {
                  footer_block {
                    text {
                      line
                    }
                    logo {
                      source_url
                    }
                    link {
                      text
                      url
                    }
                    include_icon
                  }
                  link_icon {
                    url {
                      source_url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <FooterBlocks data={data} />}
  />
)
